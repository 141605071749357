import React from "react";
import { StaticQuery, graphql } from "gatsby";
import BookThumb from "../Book/BookThumb";
import "./BookSeries.scss";

export default function OtherBooks(props) {

  return (
    <div
      className={
        "series u-pad-top-md u-pad-btm-md " +
        (props.tag ? "series--" + props.tag.replace(/\s+/g, '-').toLowerCase() : "")
      }
    >
      <div className="series__intro">
        <h3 className="series__heading u-mar-btm-md">
          Series: <strong>{props.tag}</strong>
        </h3>
      </div>
      <ul className="books__listing">
        <StaticQuery
          query={graphql`
            query seriesTPC {
              posts: allMarkdownRemark(
                sort: { order: ASC, fields: [frontmatter___date] }
                filter: { frontmatter: { tags: { in: "The Psychic Curse" } } }
                limit: 3
              ) {
                edges {
                  node {
                    id
                    frontmatter {
                      date(formatString: "MMMM DD, YYYY")
                      slug
                      title
                      series
                      bookCover {
                        childImageSharp {
                          gatsbyImageData(
                            width: 200
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            quality: 100
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={(data) => {
            const Books = data.posts.edges.filter(
              (edge) => !!edge.node.frontmatter.date
            );

            return Books.map((edge) => (
              <li key={edge.node.id} className="books__listing-item u-mar-top-sm u-mar-btm-sm">
                <BookThumb
                  series={edge.node.frontmatter.series}
                  link={edge.node.frontmatter.slug}
                  image={edge.node.frontmatter.bookCover}
                  title={edge.node.frontmatter.title}
                />
              </li>
            ));
          }}
        />
      </ul>
    </div>
  );
}
