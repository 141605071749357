import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Header from "../components/Header/Header";
import Hero from "../components/Heading/Hero";
import PageHeading from "../components/Heading/PageHeading";
import Container from "../components/Container/Container";
import BookThumb from "../components/Book/BookThumb";
import SeriesTPC from "../components/BookSeries/SeriesTPC";
import Footer from "../components/Footer/Footer";
import "./books.scss";

export default function Books({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  const Books = edges.filter((edge) => !!edge.node.frontmatter.date);

  return (
    <main className="books">
      <Helmet title="Yvette Russell | Books" defer={false} />
      <Header />
      <Hero>
        <PageHeading>
          <h2>
            <strong>Books</strong> by <strong>Yvette Russell</strong>
          </h2>
          <p>
            Take a look at my completed words or sneek a peek at what I’m
            currently working on. All books are available to read online.
          </p>
        </PageHeading>
      </Hero>
      <Container name="books" type="background-climb">
        <ul className="books__listing">
          {Books.map((edge) => (
            <li
              key={edge.node.id}
              className="books__listing-item u-mar-top-sm u-mar-btm-sm"
            >
              <BookThumb
                wip={edge.node.frontmatter.wip}
                link={edge.node.frontmatter.slug}
                image={edge.node.frontmatter.bookCover}
                title={edge.node.frontmatter.title}
                series={edge.node.frontmatter.series}
              />
            </li>
          ))}
        </ul>
        <SeriesTPC tag="The Psychic Curse" />
      </Container>
      <Footer />
    </main>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: "standalone" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            series
            wip
            bookCover {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
  }
`;
